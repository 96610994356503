
#app {
  padding: 0 15px;
}
h1 {
  font-size: 20px;
  text-align: center;
  color: rgb(0, 114, 229);
}
.MuiTableCell-root {
  padding: 2px 5px !important;
}
.editcT {
  display: flex;
  margin-bottom: 20px;
  .MuiFormControl-root {
    margin-left: 5px;
  }
}
.stackEdit {
  margin-bottom: 20px;
}
.inputBig {
  &.phone {
    min-width: 45%;
    input {
      min-width: 100px;
    }
  }
  &.fare {
    width: 30%;
  }
  &.name {
    min-width: 45%;
    input {
      min-width: 100px;
    }
  }
}

.inputSmall {
  width: 70px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall.css-dsuxgy-MuiTableCell-root, .MuiSelect-select {
  padding: 10px 5px;
}

div#demo-simple-select {
  padding: 10px 18px;
  min-width: 50px;
}


input#outlined-controlled\ margin-none {
  padding: 10.5px 5px;
}